@import "../../../assets/style/mixins.less";

.main {
    padding: 20px;

    .filter-select {
        width: 100%;
        border-radius: 5px;

        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
        }
    }
}

.dashboard-table {
    width: 40%;
    height: auto;
    box-sizing: border-box;
    padding: 0px 0px;

    .table-cell {
			min-height: 40px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
			border: 1px solid black;
			padding-left: 10px;
			padding-right: 10px;
			text-align: center;
    }
}