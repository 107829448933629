@import "../../../assets/style/mixins.less";

.container {
  height: @header-height;
  position: sticky !important;
  top: 0;
  z-index: 2;
  display: flex;
  border-bottom: 1px solid #f0f0f0;

  &:global(.ant-layout-header){
    padding: 0px 0px !important;
  }
  

  &.main-header {
    z-index: 3;
  }

  .wrapper {
    min-width: @header-height;
    height: @header-height;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;
    padding: 0 15px;

    &:hover {
      background-color: #00000005;
    }

    &.right {
      border-right: 1px solid #f0f0f0;
    }

    &.left {
      border-left: 1px solid #f0f0f0;
    }

    &.grow {
      flex-grow: 1;
      justify-content: flex-start;
      cursor: unset;

      &:hover {
        background-color: unset;
      }
    }
  }

  .icon {
    font-size: 20px;

    &.large {
      font-size: 30px;
    }

    &.color {
      color: @blue1;
    }
  }

  .name {
    margin-bottom: 0;
    color: @navy1;
    font-weight: bold !important;
  }

  .nameBlack {
    margin-bottom: 0;
    color: 'black';
    font-weight: bold !important;
  }

  .input {
    height: 36px;
    border-radius: 50px;
    max-width: 400px;

    .icon {
      margin-right: 5px;
    }
  }

  .carets {
    display: flex;
    flex-direction: column;
  }

  .option {
    margin: "20px 25px 20px 25px";
    height: 50px;

  }
}
