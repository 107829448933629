@import "../../../../../assets/style/mixins.less";

.modal-container {
    margin-top: 20px;

    .filter-input {
        background-color: white;
        border: rgba(0, 0, 0, 0.2) solid 1px;
        width: 100%;
        border-radius: 5px;
    }

    .filter-select {
        width: 100%;
        border-radius: 5px;

        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
        }
    }

    .filter-title {
        // text-align: right;
    }

    .table {
        margin-top: 20px;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        border-radius: 5px;
    }

    /* table */
    :global(.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
    }

    .important-text {
        font-weight: bold !important;
    }

    .table-row{
        cursor: pointer;
    }

    .pagination{
        margin-top: 20px;
    }

}

.hidden {
    display: none;
}