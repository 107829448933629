@import "../../../assets/style/mixins.less";

.base-layout {
    width: 50%;
    height: 100vh;

    .layout-header {
        background-color: @white !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .logo-header {
            height: 40px;
            object-fit: contain;
            margin-left: 15px;
        }

        .title-header {
            font-size: 35px;

        }
    }

    .layout-content {
        background-color: @white !important;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .login-box {
            background-color: @white;
            border: 1px rgba(0, 0, 0, 0.1) solid;
            border-radius: 10px;
            width: 600px;
            padding: 40px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 100px;

            .login-title {
                font-size: 25px;
                text-align: center;
                width: 350px;
                border-bottom: 1px rgba(0, 0, 0, 0.5) solid;
                margin-top: 40px;
                padding-bottom: 30px;
                font-weight: bold !important;
                color: @navy1
            }

            .login-form {
                font-size: 15px;
                width: 450px;
                margin-top: 50px;

                .login-input {
                    border-radius: 5px;
                }

                .error-input {
                    color: @red1;
                    font-size: 14px;
                }

                .forgot-password {
                    color: @blue1;
                    font-size: 14px;
                    cursor: pointer;
                }
            }

            .error-request {
                margin-top: 60px;
                color: @red1;
                font-size: 14px;
            }

            .login-button {
                font-size: 15px;
                width: 450px;
                color: white;
                border-radius: 5px;
                background-color: @navy1;
            }

            .register {
                font-size: 15px;
                margin-top: 60px;
                margin-bottom: 40px;

                .register-text {
                    color: @blue2;
                }
            }
        }
    }

}