@import '../../../../assets/style/mixins.less';

.main {
    margin-top: 20px;

    .table {
        margin-top: 20px;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        border-radius: 5px;
    }

    .table-button {
        border: rgba(0, 0, 0, 0) solid 0px !important;
        margin-right: 15px;
    }

    /* table */
    :global(td.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
        background-color: white !important;
    }
    :global(th.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
        border: rgba(0, 0, 0, 0.1) solid 1px !important;
        font-weight: bold;
        color: black;
        background-color: #fafafa !important;
    }
    .row-header {
        border: rgba(0, 0, 0, 0.2) solid 2px !important;
        color: red;
    }
    .row-item {
        border: #2c3782 solid 1px !important;
        padding-left: 7px;
        padding-right: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px;
        cursor: pointer;
    }

    .important-text {
        font-weight: bold !important;
    }

    .submit-button {
        background-color: @green2 !important;
        width: 100%;
        color: @white !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cancel-button {
        background-color: @red2 !important;
        width: 100%;
        color: @white !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .filter-title {
        text-align: left;
    }

    .filter-input {
        background-color: white;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        width: 90%;
    }

    .ghost-button {
        width: auto;
        border-color: @navy1;
        color: 'white';
    }
}
