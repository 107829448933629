@import "../../../assets/style/mixins.less";

.text {
  color: @navy1;
  margin-right: 10px;
}

.button {
  border: none !important;
  border-radius: 50px !important;

  &:hover {
    background-color: #f1f1f1 !important;
  }
}
