@import "../../../assets/style/mixins.less";

.layout {
  height: calc(100vh - @header-height * 2);

  .body {
    height: calc(100vh - @header-height);
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 8px !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dadce0;
      border-radius: 10px;
    }
  }
}
