@import "../../../assets/style/mixins.less";

.container {
  height: 100vh;
  position: sticky !important;
  top: 0;
  overflow: auto;
  z-index: 1;
  background-color: #2c3782 !important;

  .topbar {
    height: 120px;
    display: flex;
    align-items: center;
    padding: 0 40px;
  }

  &.collapsed .topbar {
    justify-content: center;
    padding: 0 20px;
    height: 70px;
  }

  .menu {
    border-right: none;
    max-height: calc(100vh - @header-height);
    overflow-x: hidden;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 6px !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dadce0;
      border-radius: 10px;
    }

    * {
      transition: all 0s ease-in-out !important;
    }

    .menu-item {
      &:hover,
      &.active {
        .title {
          color: #fff;
        }

        .icon svg {
          filter: brightness(200%);
        }
      }
    }

    .menu-item-3 {
      .title {
        position: relative;
        margin-left: 20px;

        &::before {
          content: "";
          width: 7px;
          height: 7px;
          background-color: #8294b9;
          position: absolute;
          border-radius: 50px;
          left: -20px;
          top: 35%;
        }
      }

      &:hover,
      &.active {
        .title::before {
          background-color: #fff;
        }
      }
    }

    .submenu-1 {
      &:global(.ant-menu-submenu-open),
      &:global(.ant-menu-submenu-active),
      &.active {
        .submenu-title-1 {
          color: #fff;
        }
        .submenu-icon-1 svg {
          filter: brightness(200%);
        }
      }
    }

    .submenu-2 {
      &:global(.ant-menu-submenu-open),
      &:global(.ant-menu-submenu-active),
      &.active {
        .submenu-title-2 {
          color: #fff;
        }
        .submenu-icon-2 svg {
          filter: brightness(200%);
        }
      }
    }
  }

  &:global(.ant-layout-sider-collapsed) {
    :global(.ant-menu-item-icon) {
      margin-left: -5px !important;
      margin-top: 5px !important;
    }
  }



  
}
:global(.ant-menu-submenu-title) {
  margin-inline: 0px !important;
  margin-block: 0px !important;
}

:global(.ant-menu-sub) {
  background-color:  #2c3782 !important;
 }
 


:global(.ant-menu.ant-menu-sub.ant-menu-vertical) {
  :global(.ant-menu-item-icon) svg {
    margin-top: 7px;
    margin-bottom: -7px;
  }

  .menu-item {
    &:global(.ant-menu-item-active),
    &.active {
      .title {
        color: #fff;
      }
      .icon svg {
        filter: brightness(200%);
      }
    }
  }

  .submenu-2 {
    &:global(.ant-menu-submenu-active),
    &.active {
      .submenu-title-2 {
        color: #fff;
      }
      .submenu-icon-2 svg {
        filter: brightness(200%);
      }
    }
  }
}
