@import "../../../assets/style/mixins.less";

.container {
  position: sticky !important;
  top: @header-height;
  overflow: auto;
  z-index: 1;
  background-color: #fff !important;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  height: calc(100vh - 64px);

  .wrapper {
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 15px;
    cursor: pointer;
    min-height: @header-height;
    gap: 10px;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #00000005;
    }

    .icon {
      font-size: 16px;
    }

    .name {
      font-size: 12px;
      color: #333;
    }
  }
}
