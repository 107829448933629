@import "../../../../assets/style/mixins.less";

.col-block{
    display: flex !important; 
    justify-content: center;

    .navigation-block{
        height: 100%; 
        border: 2px solid @navy1; 
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold !important;
        text-align: center;
        font-size: 1vw;
      
    }
}

.line-grid-block{
    border-top: 2px solid  @navy1; 
    height: 30px;
    border-right: 2px solid  @navy1; 
    border-left: 2px solid  @navy1; 
    &.inner {
        border-left: none; 
    }
}

.side-line-grid-block{
    border-right: 2px solid  @navy1;  
    height: 30px
}

