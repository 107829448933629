@import "../../../../assets/style/mixins.less";

.main {
    margin-top: 20px;

    .filter-input{
        background-color: white;
        border: rgba(0, 0, 0, 0.2) solid 1px;
        width: 100%;
    }

    .filter-title{
        text-align: right;
    }

    .filter-select {
        width: 100%;
        border-radius: 5px;

        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
        }
    }

    
      
    .add-button{
        background-color: @navy1 !important;
        width: 100%;
        color: @white !important;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }


    .add-button:hover{
        background-color: @navy2 !important;
        
    }



    .table {
        margin-top: 20px;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        border-radius: 5px;
    }

    .pagination{
        margin-top: 20px;
    }

    .table-icon{
        color: @black;
        font-size: 15px;
    }

    .warning-net-weight {
        font-weight: bold !important;
        color: @orange1;
        &.high{
            color: @red2;
        }
    }

    .important-text {
        font-weight: bold !important;
    }

    .info-block{
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        padding: 20px;
        border-radius: 10px;
    }

    /* table */
    :global(.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
    }

     :global(.ant-input-disabled) {
        // font-size: 14px;
        background-color: @white !important;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        // color: @black !important
    }

    
}