@import "../../../../../assets/style/mixins.less";

.modal-container {
    margin-top: 20px;

    .filter-wrapper {
        background-color: white;
        border: rgba(0, 0, 0, 0.2) solid 1px;
        min-height: 40px;
        padding: 5px;
        width: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px
    }

    .filter-input {
        background-color: white;
        // border: rgba(0, 0, 0, 0.2) solid 1px;
        width: 38%;
        height: 30px;
        border-radius: 5px;
        padding-left: 5px;
        font-size: 13px;
        border-color: white;
        box-shadow: none;
    }
    .filter-input:focus {
        border-color: white;
        box-shadow: none;
    }
    .filter-input:hover {
        border-color: white;
        box-shadow: none;
    }

    .filter-select {
        width: 100%;
        border-radius: 5px;

        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
        }
    }

    .filter-title {
        margin-top: 6px;
    }

    .table {
        margin-top: 20px;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        border-radius: 5px;
    }

    /* table */
    :global(.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
    }

    .important-text {
        font-weight: bold !important;
    }

    .table-row{
        cursor: pointer;
    }

    .pagination{
        margin-top: 20px;
    }

    .dropdown {
        width: auto;

        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
    }
    .dropdown-item {
        width: auto; 
        height: 35px;
        padding: 5px;
        display: flex;
        flex-direction: row;  
        cursor: pointer;
        // &:hover {
        //     background-color: #f1f1f1;
        // }
    }
    .dropdown-item-hover {
        width: auto; 
        height: 35px;
        padding: 5px;
        display: flex;
        flex-direction: row;  
        cursor: pointer;
        background-color: #f1f1f1;
    }
    .chip  {
        border-radius: 8px;
        font-size: 13px;
        padding-left: 6px;
        padding-right: 6px;
    }
    .error-text{
        font-weight: bold;
        color:#f27e7c;
        margin-left: 5px;
    }

}

.error-form{
    border: 1px @red1 solid !important;
}

.hidden {
    display: none;
}

 