@import '../../../../../assets/style/mixins.less';

.main {
    margin-top: 50px;

    .filter-input {
        background-color: white;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        width: 85%;
    }

    .filter-title {
        text-align: left;
    }

    .filter-select {
        width: 100%;
        border-radius: 5px;

        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
        }
    }

    .block-title {
        text-align: left;
        color: @navy1;
        font-size: 16px;
    }
    .block-input {
        background-color: white;
        border: @navy1 solid 1px !important;
        width: 85%;
    }
    .button {
        width: auto;
    }
    .ghost-button {
        width: auto;
        border-color: @navy1;
        color: 'white';
    }

    /* table */
    :global(.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
    }

    .warning-input {
        border: @red2 solid 1px !important;
    }

    .add-button {
        background-color: @green2 !important;
        width: auto;
    }
    .delete-button {
        background-color: @red2 !important;
        width: auto;
    }

    // :global(.ant-table-body::-webkit-scrollbar) {
    //     display: none;
    //   }
}
