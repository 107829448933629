@import "../../../../assets/style/mixins.less";

.main {
    margin-top: 20px;

    .filter-input{
        background-color: white;
        border: rgba(0, 0, 0, 0.2) solid 1px;
        width: 100%;
    }

    .filter-title{
        text-align: right;
    }

    .filter-row{
        margin-top: 10px;
    }

    .filter-row-title{
        margin-top: 50px;
        color: @navy1;
        font-weight: bold !important;
        font-size: 18px;
    }

    .export-button{
        background-color: @navy1 !important;
        width: 100%;
        color: @white !important;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }


    .export-button:hover{
        background-color: @navy2 !important;
        
    }


    .filter-select {
        width: 100%;
        border-radius: 5px;

        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
            color: @black !important;
        }
        
    }
}