@import "../../../assets/style/mixins.less";

.container {
  background-color: #fff !important;
  height: @header-height;
  display: flex;
  align-items: center;
  padding-inline: 25px !important;
  border-top: 1px solid #f0f0f0;

  * {
    margin: 0;
  }
}
